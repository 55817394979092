<template>
    <div style="width:100vw;margin:-12px;">
        <!-- app bar -->
        <top-nav title="Ponches"></top-nav>

        <div v-if="timestamps != null" class="pt-5 mx-3">
            <v-text-field v-model="q" class="rounded-xl mb-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" hide-details dense prepend-inner-icon="mdi-magnify" clearable></v-text-field>
            <div v-if="q && filteredTimestamps.length === 0" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="q" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                <span>Mostrando {{ filteredTimestamps.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
            </div>
            <div class="d-flex align-center my-3">
                <v-menu v-model="menu" :close-on-content-click="false"  transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip link v-bind="attrs" v-on="on">
                            <v-icon left class="mr-1">mdi-calendar</v-icon>
                            <span>{{ date }}</span>
                        </v-chip>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable @input="getTimestamps(); menu = false"></v-date-picker>
                </v-menu>
            </div>
            <!-- content -->
            <v-list v-if="filteredTimestamps.length && !loading && !q" two-line class="pt-0">
                <template v-for="(t, i) in filteredTimestamps">
                    <v-list-item :key="`timestamp-${i}`">
                        <v-list-item-avatar>
                            <v-img v-if="t.profilePic" :src="t.profilePic"></v-img>
                            <v-avatar v-else color="accent" size="36" class="white--text">{{ getCustomerInitials(t.customer) }}</v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-left">{{ getCustomerFullName(t.customer) }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <div>{{ (new Date(`${t.date}T04:00:00`)).toLocaleDateString('es-PR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</div>
                                <div> {{ t.time }}</div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon @click="timestamp = t; deleteDialog = true">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider inset v-if="i < filteredTimestamps.length - 1" :key="`divider-${t.id}`"></v-divider>
                </template>
            </v-list>
            <div v-else-if="!loading && !q" class="d-flex justify-center align-center" style="width: 100%;line-break:anywhere;">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron ponches para este día</span>
            </div>

            <v-dialog v-model="dialog" width="450" content-class="rounded-xl">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark fixed bottom right fab class="addBtn" v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>

                <v-form v-model="valid" ref="form" @submit.prevent="submit">
                    <v-card>
                        <v-card-title class="justify-space-between">
                            <h3 class="petu-dark-green">Ponche Manual</h3>
                            <v-btn @click="dialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-subtitle>Seleccionar fecha y hora</v-card-subtitle>

                        <v-card-text>
                            <v-autocomplete v-model="customerID" :items="customers" item-text="firstName" item-value="id" :rules="rules" class="rounded-lg" ref="card_id" :disabled="loading" prepend-inner-icon="mdi-magnify" label="Empleado" dense>
                                <template v-slot:selection="data">
                                    {{ data.item.firstName }} {{ data.item.lastName }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item.firstName }} {{ data.item.lastName }}
                                </template>
                            </v-autocomplete>
                            <v-text-field v-model="manualDate" type="date" label="Fecha" :rules="rules" hint="mm/dd/yyyy" prepend-inner-icon="mdi-calendar" class="mb-3 rounded-lg" dense required persistent-hint></v-text-field>
                            <v-text-field v-model="manualTime" type="time" label="Hora" :rules="rules" prepend-inner-icon="mdi-clock-time-four-outline" class="rounded-lg" dense required></v-text-field>
                        </v-card-text>

                        <v-card-actions class="justify-end px-4 pt-0 pb-4">
                            <v-btn @click="dialog = false" text color="green darken-1">Cancelar</v-btn>
                            <v-btn :disabled="!valid || loadingManual" :loading="loadingManual" type="submit" text color="green darken-1">Someter</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>

        <v-dialog v-model="deleteDialog" persistent max-width="500" content-class="rounded-xl">
            <v-form @submit.prevent="deleteTimestamp">
                <v-card>
                    <v-card-title class="justify-space-between">
                        <h3 class="petu-dark-green">Eliminar Ponche</h3>
                        <v-btn icon @click="deleteDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-subtitle>¿Está segur@ de que quiere <strong>eliminar</strong> ponche?</v-card-subtitle>
                    <v-card-text>
                        <div>{{ getCustomerFullName(timestamp.customer) }}</div>
                        <div>{{ (new Date(`${timestamp.date}T04:00:00`)).toLocaleDateString('es-PR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} @ {{ timestamp.time }}</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="deleteDialog = false" color="green darken-1" text>Cancelar</v-btn>
                        <v-btn :disabled="loadingDelete" :loading="loadingDelete" type="submit" color="green darken-1" text>Si</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="loading" hide-overlay persistent content-class="rounded-lg" width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando ponches</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TopNav from '@/components/admin/TopNav';
import { PetuCustomer } from '@/classes/customer'
import { PetuTimestamp } from '@/classes/timestamp'

export default {
    data:() => ({
        loading: false,
        loadingManual: false,
        loadingDelete: false,
        valid: false,
        menu: false,
        dialog: false,
        date: null,
        manualDate: null,
        timestamp: {},
        manualTime: (new Date()).toLocaleTimeString('en-US', { hour12: false }),
        timestamps: [],
        customers: [],
        customerID: null,
        deleteDialog: false,
        q: '',
        rules: [v => !!v || 'Campo es requerido'],
    }),
    async mounted() {
        this.date = localStorage.getItem('petu-date') || this.currentDate,
        this.manualDate = this.currentDate
        await this.getTimestamps()
        this.customers = await (new PetuCustomer()).getList({
            filter: { isAdmin: { eq: true } },
            limit: 999
        })
    },
    methods: {
        async getTimestamps() {
            this.loading = true
            localStorage.setItem('petu-date', this.date) 
            let timestamps = await (new PetuTimestamp()).getList({
                filter: { date: { eq: this.date } }
            })
            for (let t of timestamps) {
                if (t.customer.profilePic && t.customer.profilePic.key) {
                    let cc = new PetuCustomer(t.customer)
                    await cc.getProfilePicSrc()
                    t.profilePic = cc.image.imgUrl
                }
            }

            this.timestamps = timestamps
            this.loading = false
        },
        compare(a, b) {
            const key = 'time'
            
            // const isASC = [0, 2].includes(this.sortBy)

            // if (isASC) {
                // return a[key] < b[key] ? - 1 : Number(a[key] > b[key])
            // } else {
                return this.timeTo24hr(a[key]) > this.timeTo24hr(b[key]) ? - 1 : Number(this.timeTo24hr(a[key]) < this.timeTo24hr(b[key]))
            // }
        },
        async submit() {
            if (!this.valid || !await this.$store.getters['auth/isPetuPunchAdmin']) return
            try {
                this.loadingManual = true
                let timestamp = new PetuTimestamp({ customerID: this.customerID, date: this.manualDate, time: this.timeTo12hr(this.manualTime) })
                let count = await timestamp.save()
                let timestampType = count % 2 === 0 ? 'entrada' : 'salida'
                // this.dialog = false
                this.$root.$emit('alert', { status: 'success', message: `Ponche manual de ${timestampType} ha sido registrado` })
                if (this.date === this.manualDate) this.getTimestamps()
            } catch (err) {
                console.error(err)
               this.$root.$emit('alert', { status: 'error', message: 'Error al momento de someter ponche' })
            } finally {
                this.loadingManual = false
            }
        },
        zeroPad: (num) => String(num).padStart(2, '0'),
        /**
         *  time to 12hr
         *	@param  {String}    - time
        *	@return {String}    - time in 12hr format
        */
        timeTo12hr(time) {
            time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

            if (time.length > 1) {
                time = time.slice(1)
                time[5] = +time[0] < 12 ? ' AM' : ' PM'
                time[0] = +time[0] % 12 || 12
            }

            return time.join('')
        },
        /**
         *  time to 24hr
         *	@param  {String}    - time
        *	@return {String}    - time in 24hr format
        */
        timeTo24hr (time) {
            const [time_12hr, modifier] = time.split(' ')
            let [hours, minutes, seconds] = time_12hr.split(':')

            if (hours === '12')     hours = '00'
            if (modifier === 'PM')  hours = parseInt(hours, 10) + 12

            return `${this.zeroPad(hours)}:${this.zeroPad(minutes)}:${this.zeroPad(seconds)}`
        },
        getCustomerInitials(customer) {
            let c = new PetuCustomer(customer)
            return c.getInitials()
        },
        getCustomerFullName(customer) {
            let c = new PetuCustomer(customer)
            return c.getFullName()
        },
        async deleteTimestamp() {
            this.loadingDelete = true
            const t = new PetuTimestamp({ id: this.timestamp.id })
            await t.delete()
            this.loadingDelete = false
            this.deleteDialog = false
            this.getTimestamps()
        },
    },
    computed: {
        ...mapGetters({  }),
        filteredTimestamps() {
            let q = this.q?.toLowerCase() || null
            return (!this.q 
            ? this.timestamps
            : this.timestamps.filter((t) => {
                return t.customer.firstName.toLowerCase().includes(q)
                || t.customer.lastName.toLowerCase().includes(q)
                || t.customer.email.toLowerCase().includes(q)
                || t.time.includes(q)
            })).sort(this.compare)
        },
        resultText() {
            return this.filteredTimestamps.length === 1 ? 'resultado' : 'resultados'
        },
        /**
         *  get current date
         *	@return {String} - current date in Puerto Rico with ISO format
        */
        currentDate :() => new Date().toLocaleString('sv', {timeZone: 'America/Puerto_Rico'}).substring(0, 10),
    },
    components: { TopNav }
}
</script>